.video-container {
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .responsive-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
  }
