.video-overlay {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

  
  .overlay-content {
    padding: 2rem;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    backdrop-filter: blur(5px);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .overlay-content:hover{
    transform: scale(1.08);
}
  
  .title {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .tagline {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-style: italic;
  }
  
  .features {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  
  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
    
    .tagline {
      font-size: 1rem;
    }
    
    .features {
      flex-direction: column;
      gap: 1rem;
    }
  }