/* Ensures the whole div is at the top and the contents are aligned horizontally */
.navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: none; /* Background color for the navigation bar */
    /* border-bottom: 1px solid #ddd; Optional: add a border below the navigation bar */
}
@media screen and (max-width: 1000px) {
    .navContainer {
      padding: 5px;
  
    }
    .menuIconContainer{
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
  }

.navBar {
    display: flex;
    list-style: none; /* Removes bullet points */
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
}

.navBar li {
    margin: 0 15px; /* Space between navigation items */
    color:white;
    transition: all 0.3s ease;

}
.navBar a {
    text-decoration: none;
    color:inherit;
 
}
/* Center the title within the container */
.centeredTitle {
    position: absolute;
    top: 0;
    color:white;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the text both horizontally and vertically */
    z-index: 1; /* Ensure it is above other content */
}

.navBar li:hover {
    color: #333; /* Changes text color on hover */
    cursor: pointer; /* Changes the cursor to a pointer */
    border-radius: 5px; /* Rounds the corners of the background */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for color changes */
    transition: text-decoration 1s; /* Smooth transition for color changes */
    text-decoration: underline;
}

.cartContainer {
    display: flex;
    align-items: center;
}

.shoppingCart {
    width: 24px; /* Adjust the size of the cart icon */
    height: auto; /* Maintain aspect ratio */
    cursor:pointer;

}

.menuIcon{
    width:24px;
    height:24px;
}
